var booleanValidate = require('shared').booleanValidate;
var or = booleanValidate.or;
var emptyValidator = require('./emptyValidator');
var isoCountryCode = require('./validateIsoCountryCode');

function validateCountryCode(countryCode) {
	var validateEmpty = emptyValidator.bind(null, countryCode);
	var validateIsoCountryCode = isoCountryCode.bind(null, countryCode);
	var validateOr = or(validateEmpty, validateIsoCountryCode);
	return validateOr();
}

module.exports = validateCountryCode;