var shared = require('shared');
var promise = shared.promise;
var isNumeric = shared.isNumeric;
var isPositiveNumber = require('./isPositiveNumber');

function nonNegativeNumberValidator(number) {
	var def = promise();
	if(!isNumeric(number) || !isPositiveNumber(number))
		def.reject(new Error('Expected a positive number'));
	else
		def.resolve();
	
	return def.promise;
}

module.exports = nonNegativeNumberValidator;
