
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<section data-name="headerRegion" class="mainPresenter-header"></section>\r\n<section data-name="searchRegion" class="mainPresenter-search"></section>');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
