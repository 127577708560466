const dicts = require('../dictionaries.json')

module.exports = function() {
	const languages = window.acceptedlanguages.accepted;
    for(var i = 0; i < languages.length; i++) {
        let language = languages[i];
        if(dicts[language])
            return dicts[language];
    }
    return dicts.en;
}
