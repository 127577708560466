const $ = require('jquery');

module.exports = {
    in: function animateIn(overlayElement, wrapperElement) {
        var backgroundAnimation = $.Velocity(overlayElement, {
            opacity: [1, 0]
        }, {
            duration: 250
        });
        var boxAnimation = $.Velocity(wrapperElement, {
            scaleY: [1, 0],
            scaleX: [1, 0.25],
            opacity: [1, 0]
        }, 250, 'ease-in-out');
        return Promise.all([backgroundAnimation, boxAnimation]);
    },
    out: function animateOut(overlayElement, wrapperElement) {
        var backgroundAnimation = $.Velocity(overlayElement, {
            opacity: [0, 1]
        }, {
            duration: 250
        });
        var boxAnimation = $.Velocity(wrapperElement, {
            scaleY: [0, 1],
            scaleX: [0.25, 1],
            opacity: [0, 1]
        }, 250, 'ease-in-out');
        return Promise.all([backgroundAnimation, boxAnimation]);
    }
};
