function hashQueryBuilder(baseHashName) {
	var c = {};
	var queryBuilder = require('./queryBuilder')('#' + baseHashName);
	
	c.add = queryBuilder.add;
	c.toString = queryBuilder.toString;

	return c;
}

module.exports = hashQueryBuilder;