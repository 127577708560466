function setViewData(view, data) {
	var invalidValues = [undefined, null, 'undefined', 'null'];

	for(var element in data){
		if(invalidValues.indexOf(data[element]) >= 0)
			data[element] = '';
	}
	view.setData(data);
}

module.exports = setViewData;