module.exports = function headerPresenter() {
    const view = require('./headerPresenter.tpl.html');
    var c = {};

    c.activate = function(region) {
        view.activate(region);
    };

    return c;
};
