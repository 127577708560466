var newNumberChangeEventListener = require('./changeEventListener/numberChangeEventListener');
var newEventListenerCore = require('./eventListenerCore');

function changeEventListener() {	
	var c = {};
	var numberChangeEventListener = newNumberChangeEventListener();
	var eventListenerCore = newEventListenerCore('change');

	c.add = function(element, callback){
		if(element.type == 'number')
			numberChangeEventListener.add(element, callback);
		else
			eventListenerCore.add(element, callback);
	};

	c.remove = function(element, callback){
		if(element.type == 'number')
			numberChangeEventListener.remove(element, callback);
		else
			eventListenerCore.remove(element, callback);
	};

	c.enable = function() {
		eventListenerCore.enable();
		numberChangeEventListener.enable();
	};

	c.disable = function() {
		eventListenerCore.disable();
		numberChangeEventListener.disable();
	};

	return c;
}

module.exports = changeEventListener;