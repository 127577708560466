
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<label data-property="text"></label>');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
