var document = require('./document');
var newChangeEventListener = require('./changeEventListener');
var newEmitEvent = require('./emitEvent');
var negotiateSetup = require('./select/negotiateSetup');
var createOptions = require('./select/createOptions');
var classManipulator = require('./classManipulator')();
var newNegotiateReadOnly = require('./negotiateReadOnly');
var newFieldErrorPresenter = require('./fieldErrorPresenter');
var createEmptyItem = require('./select/createEmptyItem');
var negotiateColor = require('./select/negotiateColor');
var negotiateSetRequired = require('./negotiateSetRequired');

function selectPresenter(optionalSetup) {
    var c = {};
    var view = require('./selectView.tpl.html');
    var setup = negotiateSetup(optionalSetup);
    var valueModel = setup.models.value;
    var displayModel = setup.models.display; 
    var fieldErrorPresenter = newFieldErrorPresenter(valueModel);
    var negotiateReadOnly = newNegotiateReadOnly(valueModel);
    var emptyItem = createEmptyItem(setup);
    var _items = [emptyItem];
    var selectElement = view.selectElement;
    var _region;
    var changeEventListener = newChangeEventListener();
    var valueChangedEventEmitter = newEmitEvent();
    var itemChangedEventEmitter = newEmitEvent();
    classManipulator.addTo(selectElement, 'form-control');

    c.activate = function(region) {
        _region = region;
        fieldErrorPresenter.activate(view.selectWrapper);
        negotiateReadOnly.activate(selectElement);
        view.activate(region);
        changeEventListener.add(selectElement, onChange);
        valueModel.subscribeChange(onModelChanged);
        negotiateSetRequired(view.selectWrapper, valueModel);
    };

    function onChange() {
        var item = _items[selectElement.selectedIndex];
        var value = item[setup.valueMember];
        var display = item[setup.displayMember];
        valueModel(value);
        displayModel(display);
        valueChangedEventEmitter(value);
        itemChangedEventEmitter(item);
        negotiateColor(value, selectElement);
    }

    function onModelChanged(value) {
        if (value === view.selectElement.value)
            return;
        changeEventListener.disable();
        selectElement.value = value;
        negotiateColor(value, selectElement);
        changeEventListener.enable();
    }

    c.deactivate = function() {
        changeEventListener.remove(selectElement, onChange);
        valueModel.unsubscribeChange(onModelChanged);
        fieldErrorPresenter.deactivate();
        negotiateReadOnly.deactivate();
        view.deactivate();
    };

    c.subscribeValueChanged = function(callback) {
        valueChangedEventEmitter.add(callback);
    };

    c.unsubscribeValueChanged = function(callback) {
        valueChangedEventEmitter.remove(callback);
    };

    c.subscribeItemChanged = function(callback) {
        itemChangedEventEmitter.add(callback);
    };

    c.unsubscribeItemChanged = function(callback) {
        itemChangedEventEmitter.remove(callback);
    };

    c.enable = function() {
        selectElement.disabled = false;
    };

    c.disable = function() {
        selectElement.disabled = true;
    };

    c.setItems = function(items) {
        disable();
        _items = [emptyItem].concat(items);
        selectElement.innerHTML = '';
        createOptions(selectElement, setup, _items);
        var value = valueModel();
        selectElement.value = value;
        negotiateColor(value, selectElement);
        enable();
    };

    function enable() {
        changeEventListener.enable();
    }

    function disable() {
        changeEventListener.disable();
    }

    return c;
}

module.exports = selectPresenter;
