function negotiateReadOnly(model) {
    var c = {};
    var isReadOnly = model.isReadOnly;
    var _element;

    c.activate = function(element) {
        _element = element;
        if (isReadOnly) {
            enableDisable(isReadOnly());
            isReadOnly.subscribeValidChange(enableDisable);
        }
    };

    function enableDisable(value) {
        _element.disabled = value;
    }


    c.deactivate = function() {
      if (isReadOnly) 
       isReadOnly.unsubscribeValidChange(enableDisable);
};

return c;
}

module.exports = negotiateReadOnly;