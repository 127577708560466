module.exports={
    "no": {
        "about": "Om",
        "advancedTracking": "Avansert sporing",
        "aProductOf": "Et produkt fra",
        "searchPlaceholder": "Fraktbrevnr / referanse / kollinr",
        "search": "Søk"
    },

    "en": {
        "about": "About",
        "advancedTracking": "Advanced tracking",
        "aProductOf": "A product of",
        "searchPlaceholder": "Waybill # / reference / package #",
        "search": "Search"
    }
}
