const locale = require('./dateTime/locale');
const moment = require('moment');

function formatDateTime(dateTime,format) {
	if (!dateTime)
		return '';
    dateTime = moment(dateTime);
	return dateTime.locale(locale).format(format);
}

module.exports = formatDateTime;
