var shared = require('shared');
var isNumeric = shared.isNumeric;
var promise = require('shared').promise;

function numericValidator(number) {
	var def = promise();
	
    if (!isNumeric(number))
        def.reject("Expected a number");
    else
        def.resolve();
    return def.promise;
}

module.exports = numericValidator;