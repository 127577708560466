var document = require('./document');

function createRegion() {
	var c = {};
	var span = document.createElement('span');
	var _region;

	c.activate = function(region) {
		region.add(span);
		_region = region;
	};

	c.deactivate = function() {
		_region.remove(span);
	};
	
	c.add = function(view) {
		span.appendChild(view);
	};
	c.appendChild = c.add;

	c.remove = function(view) {
		span.removeChild(view);
	};
	c.removeChild = c.remove;

	return c;
}

module.exports = createRegion;