function modalImagePresenter(imageSrc) {
	var c = {};

	var document = require('./document');
	var modal = document.createElement('div');
	var classManipulator = require('./classManipulator')();
	classManipulator.addTo(modal, 'modal');

	var modalDialog = document.createElement('div');
	classManipulator.addTo(modalDialog, 'modal-dialog');
	modalDialog.setAttribute('style', 'width: 720px;');
	modal.appendChild(modalDialog);

	var imagePresenter = require('./imagePresenter')(imageSrc);

	var closeButton = document.createElement('button');
	closeButton.setAttribute('class', 'close');
	closeButton.setAttribute('data-dismiss', 'modal');
	closeButton.innerHTML = 'close';
	closeButton.setAttribute('style', 'font-size:50px;');
	modalDialog.appendChild(closeButton);

	var jQueryModal = require('jquery')(modal);

	c.activate = function(){
		imagePresenter.activate(modalDialog);
	};

	c.setSrc = function(imagePath){
		imagePresenter.setSrc(imagePath);
	};

	c.toggle = function(){
		jQueryModal.modal('toggle');
		imagePresenter.setWidthPercentageRelativeToParent(100);
	};

	c.deactivate = function(){
		imagePresenter.deactivate();
	};

	return c;
}

module.exports = modalImagePresenter;
