var shared = require('shared');
var date = shared.date;
var promise = shared.promise;

function validateTime (timeSubject) {
	var def = promise();
	if(date(timeSubject, 'LT').isValid())
		def.resolve();
	else
		def.reject(new Error('Unrecognized Time Format.'));
	return def.promise;
}

module.exports = validateTime;