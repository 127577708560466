const getTransporter = require('./getTransporter');

function redirectSearch(code, search, searchType) {
	let transporter = getTransporter(code);
	if(!transporter) {
		throw new Error('Could not find transporter ' + code);
	}

	if (transporter.external)
		window.location.href = transporter.url + search;
	else if(transporter.tmsc)
		window.location.href = `http://transportsiden.no/${code}/transportsiden/index.html?id=${code}&traceNo=${search}`;
	else if(transporter.oldVersion)
		window.location.href = `http://transportsiden.no/Sporing/FrbrsokBilde.aspx?${searchType}=${search}&transp=${code}`;
	else
		window.location.href = `http://transportsiden.no/${code}/Timpex.Server/Js/Timpex.Js.Traffic.Trace.Client/Timpex.Traffic.Trace.Client.AutoTrace.html?id=${code}&traceNo=${search}`;
}

module.exports = redirectSearch;