var promise = require('shared').promise;

function optionalValidatorFactory(validator) {
	return function(value) {
		if(value)
			return validator(value);
		
		var def = promise();
		def.resolve();
		return def.promise;
	};
}

module.exports = optionalValidatorFactory;