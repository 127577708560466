var classManipulator = require('../classManipulator');
var placeholderClass = 'select-placeholder';

function negotiateColor(value, selectElement) {
    if (value === undefined || value === null) {
        classManipulator.addTo(selectElement, placeholderClass);
        return;
    }
    return classManipulator.removeFrom(selectElement, placeholderClass);
}

module.exports = negotiateColor;
