var getErrorMessage = require('./getErrorMessage');
var newClassManipulator = require('./classManipulator');

function errorPresenter() {
    var c = {};
    var view = require('./errorView.tpl.html');
    var classManipulator = newClassManipulator();
    var _region;

    c.activate = function(region) {
        _region = region;
        view.activate(region);
    };

    c.error = function(e) {
        var errorMessage = getErrorMessage(e);
        view.setData({
            errorMessage: errorMessage
        });
        classManipulator.addTo(view.errorLabel, 'has-composite-error');
        classManipulator.addTo(_region, 'has-error');
    };

    c.clear = function() {
        view.setData({
            errorMessage: ''
        });
        classManipulator.removeFrom(view.errorLabel, 'has-composite-error');
        classManipulator.removeFrom(_region, 'has-error');
    };

    c.deactivate = function() {
        view.deactivate();
    };

    return c;
}

module.exports = errorPresenter;
