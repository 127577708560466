var promise = require('shared').promise;
var unauthorized = require('./unauthorized');
var ajaxServiceCore = require('./ajaxServiceCore');
var loginRequestSender = require('./loginRequestSender');

function send(serializedData, url) {
    var def = promise();
    ajaxServiceCore(serializedData, url)
        .then(onSuccess, onFailed);

    function onSuccess(data, textStatus, jqXhr) {
        def.resolve(data);
    }

    function onFailed(jqXhr, textStatus, error) {
        if(jqXhr.status === 401)
            handleUnauthorized();
        else
            def.reject(error);
    }

    function handleUnauthorized() {
        loginRequestSender.subscribeLoginSuccess(onLoginSuccess);
        unauthorized()();
    }

    function onLoginSuccess() {
        loginRequestSender.unsubscribeLoginSuccess(onLoginSuccess);
        ajaxServiceCore(serializedData, url)
            .then(onSuccess, onFailed);
    }

    return def.promise;
}

module.exports = send;
