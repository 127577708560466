var newClassManipulator = require('./classManipulator');
var iconNameToIcon = require('./glyphIconPresenter/iconNameToIcon');
var newNegotiateClickable = require('./glyphIconPresenter/negotiateClickable');
var negotiateCssClass = require('./negotiateCssClass');

function glyphIconPresenter(iconName, onClick) {
    var c = {};
    var document = require('./document');
    var classManipulator = newClassManipulator();
    var iconSpan = document.createElement('span');
    var icon = iconNameToIcon(iconName);
    classManipulator.addTo(iconSpan, icon);
    var negotiateClickable = newNegotiateClickable(iconSpan);
    negotiateCssClass(iconSpan, 'glyphIconPresenter-clickable', onClick);

    var _region;

    c.activate = function(region) {
        _region = region;
        region.add(iconSpan);
        negotiateClickable.enable(onClick);
    };

    c.deactivate = function() {
        negotiateClickable.disable(onClick);
        _region.remove(iconSpan);
    };

    return c;
}

module.exports = glyphIconPresenter;
