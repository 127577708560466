var jquery = require('jquery');

function modalPresenter() {
    var c = {};
    var view = require('./modalConfirmationView.tpl.html');
    var modalObject = jquery(view.rootElement);

    c.activate = function(region) {
        view.activate(region);
        modalObject.modal('show');
        modalObject.on('hidden.bs.modal', onModalHidden);
    };

    function onModalHidden() {
        modalObject.off('hidden.bs.modal', onModalHidden);
        view.deactivate();
    }
    c.setHeaderText = function(headerText) {
        view.setData({
            headerText: headerText
        });
    };

    c.setContentText = function(contentText) {
        view.setData({
            contentText: contentText
        });
    };

    c.subscribeOkClicked = function(cb) {
        view.okButton.addEventListener('click', cb);
    };

    c.unsubscribeOkClicked = function(cb) {
        view.okButton.removeEventListener('click', cb);
    };
    return c;
}

module.exports = modalPresenter;
