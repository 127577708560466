var promise = require('shared').promise;

function emptyValidator(value) {
    var def = promise();
    if (value === '' || value === null || value === undefined) {
        def.resolve();
    } else {
        def.reject("Expected empty");
    }
    return def.promise;
}

module.exports = emptyValidator;
