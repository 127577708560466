var newExecuteValidate = require('./binaryAndd/newExecuteValidate');
var addOperators = require('./addOperators');

var nextBinary = function() {
    nextBinary = require('./binaryAndd');
    return nextBinary.apply(null, arguments);
};

function binaryAndd(validator, validator2) {	
    if (arguments.length == 2) {
        var execute = newExecuteValidate(validator, validator2);
        return addOperators(execute);
    }
    var first = nextBinary(validator, validator2);
    var nextArgs = [first].concat(Array.prototype.slice.call(arguments, 2));
    return nextBinary.apply(null, nextArgs);
}

module.exports = binaryAndd;
