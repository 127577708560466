var promise = require('shared').promise;
var isInteger = /^-?\d+$/;

function integerValidator(valueString) {
	var def = promise();
	if(!isInteger.test(valueString))
		def.reject(new Error("Expected an integer"));
	else
		def.resolve();

	return def.promise;
}

module.exports = integerValidator;