var negotiateUpperCase = require('./purify/negotiateUpperCase');

function purifyInput(changeEventListener, options, e) {
	if(options.type === "number" &&  e.target.value === '')
		return 0;
	if(e.target.value === '')
		return null;
	return negotiateUpperCase(changeEventListener, options, e);
}

module.exports = purifyInput;