
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<section data-name="container" class="searchPresenter-container">\r\n    <h1 class="searchPresenter-heading">Transportsiden</h1>\r\n    <form data-name="form">\r\n        <div data-name="searchRegion" class="searchPresenter-search"></div>\r\n        <div data-name="transporterRegion" class="searchPresenter-transporter"></div>\r\n        <button type="submit" class="searchPresenter-submitButton" data-translate="search"><span data-translate="search">S\xF8k</span></button>\r\n    </form>\r\n    <a class="searchPresenter-aboutLink" href="about.html"><span data-translate="about">Om</span> Transportsiden.no</a>\r\n</section>\r\n');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
