module.exports = [{
    code: '40',
    value: 'Inwards - Import'
}, {
    code: '62',
    value: 'Inwards - Norwegian goods in return'
}, {
    code: '70' ,
    value: 'Inwards - Bonded storage A'
}, {
    code: '50',
    value: 'Inwards - Temporary import'
},{
    code: '41',
    value: 'Inwards - Import from Norwegian continental shelf'
}, {
    code: '10',
    value: 'Outwards - Export'
}, {
    code: '14',
    value: 'Outwards - Bonded storage A'
}, {
    code: '80',
    value: 'Outwards - Direct transit'
}, {
    code: '20',
    value: 'Outwards - Temporary export'
}, {
    code: '30',
    value: 'Outwards - Export of temporary cleared goods'
}, {
    code: '12',
    value: 'Outwards - Norwegian equipment onboard vessel'
}, {
    code: '' ,
    value: 'Outwards - Tollpass'
}];
