var monadicAnd = require('./booleanValidate/monadicAnd');
var emptyValidate = require('./booleanValidate/emptyValidate');

emptyValidate.or = function(validate, validate2, etc) {	
	var operator = monadicAnd;
	var returnValue = emptyValidate;
	for (var i = 0; i < arguments.length; i++) {
		var arg = arguments[i];
		returnValue = operator(arg);
		operator = returnValue.or;
	};
	return returnValue;
};

emptyValidate.orr = function(validate, validate2, etc) {	
	var operator = monadicAnd;
	var returnValue = emptyValidate;
	for (var i = 0; i < arguments.length; i++) {
		var arg = arguments[i];
		returnValue = operator(arg);
		operator = returnValue.orr;
	};
	return returnValue;
};

emptyValidate.and = function(validate, validate2, etc) {	
	var operator = monadicAnd;
	var returnValue = emptyValidate;
	for (var i = 0; i < arguments.length; i++) {
		var arg = arguments[i];
		returnValue = operator(arg);
		operator = returnValue.and;
	};
	return returnValue;
};

emptyValidate.andd = function(validate, validate2, etc) {	
	var operator = monadicAnd;
	var returnValue = emptyValidate;
	for (var i = 0; i < arguments.length; i++) {
		var arg = arguments[i];
		returnValue = operator(arg);
		operator = returnValue.andd;
	};
	return returnValue;
};


module.exports = emptyValidate;
