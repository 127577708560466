var promise = require('shared').promise;

function validateRegExpCore(pattern, flag) {
	var regex = new RegExp(pattern, flag);
	return validate;

	function validate(string) {
		if (regex.test(string))
			return promise(true);
		return promise.reject('Pattern did not match');
	}

}

module.exports = validateRegExpCore;