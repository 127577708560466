
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<div class="headerPresenter-container">\r\n\t<p class="headerPresenter-text" data-text="productFrom"><span data-translate="aProductOf">Et produkt fra</span></p>\r\n    <a href="https://maritech.com/"> <img class="headerPresenter-image" src="transportsiden/img/maritech_logo.png" alt="Maritech"></a>\r\n</div>\r\n');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
