const newEmitEvent = require('./emitEvent');
const $ = require('jquery');
const { slideUp, slideDown } = require('./animation');

function collapsibleDivPresenter() {
    var c = {};
    var view = require('./collapsibleDivView.tpl.html');
    var collapseEmitter = newEmitEvent();
    var expandEmitter = newEmitEvent();
    var _region;
    var animating = false;
    var collapsed;
    var $collapsibleRegion = $(view.collapsibleRegion);
    initCollapsableRegion();

    function initCollapsableRegion() {
        $collapsibleRegion.hide();
        collapsed = true;
    }

    c.activate = function(region) {
        view.activate(region);
    };

    c.collapsibleRegion = function(){
        return view.collapsibleRegion;
    };

    c.subscribeCollapse = collapseEmitter.add;
    c.unsubscribeCollapse = collapseEmitter.remove;

    c.subscribeExpand = expandEmitter.add;
    c.unsubscribeExpand = expandEmitter.remove;

    c.expand = function() {
        if(animating)
            return;
        animating = true;
        expandEmitter();
        slideDown($collapsibleRegion, () => {
            animating = false;
            collapsed = false;
        });
    };

    c.collapse = function() {
        if(animating)
            return;
        animating = true;
        collapseEmitter();
        slideUp($collapsibleRegion, () => {
            animating = false;
            collapsed = true;
        });
    };

    c.toggle = function() {
        if(animating)
            return;

        if (!collapsed) {
            c.collapse();
        } else {
            c.expand();
        }
    };

    c.deactivate = function() {
        view.deactivate();
    };

    return c;
}

module.exports = collapsibleDivPresenter;
