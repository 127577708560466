var executeValidate = require('../executeValidate');
var _throw = require('../throw');

function newExecute(validate) {

    function execute() {
    	var fail = _throw.bind(null, execute);
    	return executeValidate(validate, arguments).then(null, fail);
    }

    return execute;
}

module.exports = newExecute;
