var document = require('../document');

function newOption(selectElement, options, items) {	
	var valueName = options.valueMember;
	var textName = options.displayMember;
	for(var i=0; i < items.length; i++) {
		var item = items[i];
		var optionElement = document.createElement('option');
		var text = item[textName];
		optionElement.text = item[textName];
		optionElement.value = item[valueName];
		selectElement.appendChild(optionElement);
	}
}

module.exports = newOption;