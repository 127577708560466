var alwaysTrue = require('../validators').alwaysTrue;
var newModel = require('../modelProperty');

function negotiateModels(options) {
	if (options.models)
		return options.models;
	return {
		value: options.model,
		display: newModel(alwaysTrue)
	};
}

module.exports = negotiateModels;