var $ = require('jquery');

function getGeometryOfElement(element) {
	var $element = $(element);
	var offset = $element.offset();
	var height = $element.outerHeight();
	var width = $element.outerWidth();
	var middleTop = offset.top + height / 2;
	var middleLeft = offset.left + width / 2;
	return {
		middleTop: middleTop,
		middleLeft: middleLeft,
		height: height,
		width: width,
		halfHeight: height/2,
		halfWidth: width/2
	};
}

module.exports = getGeometryOfElement;
