var newModelProperty = require('./modelProperty');
var alwaysTrue = require('./validators').alwaysTrue;

function readOnlyModelProperty(value) {
	var property = newModelProperty(alwaysTrue, value);
	property.isReadOnly(true);

	return property;
}

module.exports = readOnlyModelProperty;