var negotiateDefaults = require('./negotiateDefaults');

function sortAscending(objArray, propertyName) {
	return objArray.sort(compare);

	function compare(a,b) {
		var aDefault = negotiateDefaults(a[propertyName], '');
		var bDefault = negotiateDefaults(b[propertyName], '');

		if(aDefault.toString().length === 0)
			return Number.POSITIVE_INFINITY;
		if(bDefault.toString().length === 0)
			return Number.NEGATIVE_INFINITY;
		return String.prototype.localeCompare.bind(aDefault)(bDefault);
	}
}

module.exports = sortAscending;