var setViewData = require('./viewTools/setViewData');
var purifyInput = require('./input/purifyInput');
var newNegotiateReadOnly = require('./negotiateReadOnly')
var negotiateStep = require('./input/negotiateStep');
var newGlyphIconPresenter = require('./glyphIconPresenter');
var newChangeEventListener = require('./changeEventListener');
var newLabelPresenter = require('./labelPresenter');
var newFieldErrorPresenter = require('./fieldErrorPresenter');
var negotiateAttribute = require('./negotiateAttribute');
var negotiateSetRequired = require('./negotiateSetRequired');
var negotiateCssClass = require('./negotiateCssClass');

function glyphIconInputPresenter(options) {
    var c = {};
    var view = require('./glyphIconInputView.tpl.html');
    var glyphIconPresenter = newGlyphIconPresenter(options.icon, options.onIconClicked);
    var labelPresenter = newLabelPresenter(options.label);
    var changeEventListener = newChangeEventListener();
    var negotiateReadOnly = newNegotiateReadOnly(options.model);
    var fieldErrorPresenter = newFieldErrorPresenter(options.model);

    view.input.placeholder = options.placeholderText;
    view.input.type = options.type;
    negotiateAttribute('maxLength', options.model, view.input);
    negotiateStep(view.input, options);

    c.activate = function(region) {
        onModelChanged();
        options.model.subscribeChange(onModelChanged);
        glyphIconPresenter.activate(view.glyphIconRegion);
        changeEventListener.add(view.input, onValueChanged);
        labelPresenter.activate(view.labelRegion);
        negotiateReadOnly.activate(view.input);
        view.activate(region);
        fieldErrorPresenter.activate(view.wrapper);
        negotiateSetRequired(view.wrapper, options.model);
        negotiateCssClass(view.wrapper, 'iconRight', options.iconRight);
    };

    function onModelChanged() {
        changeEventListener.disable();
        setViewData(view, {
            value: options.model()
        });
        changeEventListener.enable();
    }

    c.deactivate = function() {
        glyphIconPresenter.deactivate();
        changeEventListener.remove(view.input, onValueChanged);
        labelPresenter.deactivate();
        negotiateReadOnly.deactivate();
        fieldErrorPresenter.deactivate();
        options.model.unsubscribeChange(onModelChanged);
        view.deactivate();
    };

    function onValueChanged(e) {
        var value = purifyInput(changeEventListener, options, e);
        options.model.unsubscribeChange(onModelChanged);
        options.model(value).then(reSubscribe, reSubscribe);

        function reSubscribe() {
            options.model.subscribeChange(onModelChanged);
        }
    }

    c.focus = function() {
        view.input.focus();
    };

    c.blur = function() {
        view.input.blur();
    };

    return c;
}

module.exports = glyphIconInputPresenter;
