var newEmitEvent = require('../eventListener/emitEventWorkAround');

function emitWorkaround(element){
	var emitEventCore = newEmitEvent();

	function emitEvent() {
		if (emitEvent.isDirty) {
			emitEvent.isDirty = false;
			negotiateWhenDirty();
		}
        
        function negotiateWhenDirty() {
			if (!element.validity.valid) 
				emitEventCore({ srcElement: { value: NaN } });
			else if (element.value) {
				var intValue = element.value.replace(/,/g, '.');
				intValue = parseFloat(intValue);
				emitEventCore({srcElement: {value: intValue}});
			}
			else {
				element.value = '';
				emitEventCore({srcElement: {value: ''}});
			}
		}
	}

	emitEvent.add = emitEventCore.add;
	emitEvent.remove = emitEventCore.remove;
	emitEvent.enable = emitEventCore.enable;
	emitEvent.disable = emitEventCore.disable;

	return emitEvent;
}


module.exports = emitWorkaround;