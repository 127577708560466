var executeValidate = require('../executeValidate');
var promise = require('../../promise');

function newExecute(validate, validate2) {

    function execute() {
        var errors = [];
        return executeValidate(validate, arguments).then(null, logError)
                .then(wrapExecuteValidate(arguments)).then(null, logError)
        		.then(negotiateReject);

        function logError (error) {
            if (error instanceof Array) { 
                errors = errors.concat(error);
            } 
            else
            	errors.push(error);
        }

        function negotiateReject () {
            if (errors.length >= 2) {
                var def = promise();
                if (execute.message)
                    return def.reject(execute.message);    
                return def.reject(errors);
            }            
        }

    }

    function wrapExecuteValidate(args) {
        return function() {
            return executeValidate(validate2, args);
        }
    }
    
    return execute;
}

module.exports = newExecute;
