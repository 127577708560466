const newClickEventListener = require('../clickEventListener');

module.exports = function negotiateClickable(element) {
    const c = {};
    const clickEventListener = newClickEventListener();

    c.enable = function(onClick) {
        if(onClick)
            clickEventListener.add(element, onClick);
    };

    c.disable = function(onClick) {
        if(onClick)
            clickEventListener.remove(element, onClick);
    };

    return c;
};
