var emptyValidate = require('./emptyValidate');

function executeValidate(validate, args) {
	return emptyValidate.then(onValidate);

	function onValidate() {
		return validate.apply(null, args);
	}
}

module.exports = executeValidate;