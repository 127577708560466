function formPresenter() {
	var c = {};
	var document = require('./document');
	var classManipulator = require('./classManipulator')();
	var formElement = document.createElement('form');
	var submitEventListener = require('./submitEventListener')();
	var emitEvent = require('./emitEvent')();
	formElement.role = 'form';
	var _region;

	function onSubmit(event) {
		event.preventDefault();
		emitEvent();
	}

	c.activate = function(region){
		_region = region;
		_region.add(formElement);
		submitEventListener.add(formElement, onSubmit);
	};

	c.add = function(element){
		formElement.appendChild(element);
	};
	c.appendChild = c.add;

	c.remove = function(element){
		formElement.removeChild(element);
	};
	c.removeChild = c.remove;

	c.addClass = function(className) {
		classManipulator.addTo(formElement, className);
	};

	c.removeClass = function(className) {
		classManipulator.removeFrom(formElement, className);
	};

	c.subscribeSubmit = function(callback) {
		emitEvent.add(callback);
	};

	c.unsubscribeSubmit = function(callback) {
		emitEvent.remove(callback);
	};

	c.element = function(){
		return formElement;
	};

	c.deactivate = function(){
		_region.remove(formElement);
	};

	return c;
}

module.exports = formPresenter;
