const serviceUrl = require('./serviceUrl');

module.exports = function sendRequest(body) {
    return fetch(serviceUrl('Timpex.HandleRequests'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(responses => responses.json());
};
