const newModel = require('./model');
const redirectSearch = require('./searchPresenter/redirectSearch');
const transporters = require('../transporters.json');
const updateDefaultTransporter = require('./searchPresenter/updateDefaultTransporter');
const { getTranslationDictionary } = require('core');
const {
    selectPresenter,
    submitEventListener,
    textInputPresenter,
} = require('gui');

module.exports = function() {
    const view = require('./searchPresenter.tpl.html');
    const c = {};
    const model = newModel();
    const onSubmitListener = submitEventListener();
    const translationDictionary = getTranslationDictionary();

    const searchInputPresenter = textInputPresenter({ model: model.search, placeholderText: translationDictionary.searchPlaceholder });
    const transporterSelectPresenter = selectPresenter({
        model: model.transporterCode,
        displayMember: 'name',
        valueMember: 'code'
    });

    transporters.sort((a,b) => {
        if(a.name < b.name)
            return -1;
        else if (a.name > b.name)
            return 1;
        return 0;
    });
    transporterSelectPresenter.setItems(transporters);

    c.activate = function activate(region) {
        view.activate(region);
        searchInputPresenter.activate(view.searchRegion);
        transporterSelectPresenter.activate(view.transporterRegion);

        onSubmitListener.add(view.form, onSubmit);
    };

    c.deactivate = function deactivate() {
        onSubmitListener.remove(view.form, onSubmit);

        searchInputPresenter.deactivate();
        transporterSelectPresenter.deactivate();
        view.deactivate();
    };

    function onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        updateDefaultTransporter(model.transporterCode());
        redirectSearch(model.transporterCode(), model.search(), model.searchType());
    }

    return c;
}
