var promise = require('shared').promise;

function notNullValidator(value) {
	var def = promise();
	if(value === null)
		def.reject("Expected a value");
	else
		def.resolve();
	return def.promise;
}

module.exports = notNullValidator;