function fieldErrorPresenter(model) {
    var c = {};
    var coreErrorPresenter = require('./errorPresenter')();
    var classManipulator = require('./classManipulator');
    var _region;

    c.activate = function(region) {
        _region = region;
        model.subscribeValidationSuccess(coreErrorPresenter.clear);
        model.subscribeValidationFailed(coreErrorPresenter.error);
        coreErrorPresenter.activate(_region);
    };

    c.error = function(error) {
        coreErrorPresenter.error(error);
        classManipulator.addTo(_region, 'has-error');
    };

    c.clear = function() {
        coreErrorPresenter.clear();
        classManipulator.removeFrom(_region, 'has-error');
    };

    c.deactivate = function() {
        model.unsubscribeValidationSuccess(coreErrorPresenter.clear);
        model.unsubscribeValidationFailed(coreErrorPresenter.error);
        coreErrorPresenter.deactivate();
    };

    return c;
}

module.exports = fieldErrorPresenter;
