var animations = require('./modalPresenter/animations');
var newStylesManager = require('./modalPresenter/stylesManager');
var onResize = require('./modalPresenter/onResize');
var clickEventListener = require('./clickEventListener');
var keyDownEventListener = require('./keyDownEventListener');
var newEventEmitter = require('./emitEvent');
var view = require('./modalView.tpl.html');
view.activate(document.body);

function modalPresenter() {
    var body = document.body;
    var c = {};
    var isVisible = false;
    var isAnimating = false;
    var overlayObject = view.overlay;
    var wrapperObject = view.wrapper;
    var stylesManager = newStylesManager(overlayObject, wrapperObject);
    var onClickListener = clickEventListener();
    var onEnterPressedListener = keyDownEventListener(13);
    var onEscapePressedListener = keyDownEventListener(27);
    var onCloseEmitter = newEventEmitter();

    c.show = function(){
        if(isVisible || isAnimating)
            return;
        onEnterPressedListener.add(body, onEnterOrEscapeKeyPressed);
        onEscapePressedListener.add(body, onEnterOrEscapeKeyPressed);
        onClickListener.add(view.overlay, onBackgroundClicked);
        onClickListener.add(view.closeButton, onBackgroundClicked);
        onResize.subscribe(calculateStyles);
        isAnimating = true;
        isVisible = true;
        animations.in(overlayObject, wrapperObject).then(() => {
            isAnimating = false;
        });

        calculateStyles();
    };
    c.activate = c.show;

    c.hide = function(){
        if(!isVisible || isAnimating)
            return;

        onEnterPressedListener.remove(body, onEnterOrEscapeKeyPressed);
        onEscapePressedListener.remove(body, onEnterOrEscapeKeyPressed);
        onClickListener.remove(view.overlay, onBackgroundClicked);
        onClickListener.remove(view.closeButton, onBackgroundClicked);
        onResize.unsubscribe(calculateStyles);
        isAnimating = true;
        animations.out(overlayObject, wrapperObject).then(() => {
            stylesManager.clear();
            isAnimating = false;
            isVisible = false;
            onCloseEmitter();
        });
    };
    c.deactivate = c.hide;

    c.center = function() {
        calculateStyles();
    };

    function calculateStyles() {
        stylesManager.set();
    }

    c.contentRegion = function(){
        return view.contentRegion;
    };

    c.subscribeClose = function(callback) {
        onCloseEmitter.add(callback);
    };

    c.unsubscribeClose = function(callback) {
        onCloseEmitter.remove(callback);
    };

    function onBackgroundClicked() {
        c.hide();
    }

    function onEnterOrEscapeKeyPressed() {
        c.hide();
    }

    return c;
}

module.exports = modalPresenter;
