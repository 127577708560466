var shared = require('shared');
var promise = shared.promise;

function numberInRange(from, to, number) {
	if(number < from || number > to)
		return promise.reject();
	return promise.resolve();
}

module.exports = numberInRange;
