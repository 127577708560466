var decimalSplitter = require('./formatNumber/decimalSplitter');

function formatNumber(number) {
	if(!number)
		return number;
	var splitted = number.toString().split(/[.,]+/);
	var result = separate(splitted[0]);
	negotiateDecimal(result);
	return result;

    function separate(number) {
		return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    function reverse(string) {
		return string.split('').reverse().join('');
    }

	function negotiateDecimal() {
		if(!splitted[1])
			return;
		var reversedDecimal = reverse(splitted[1]);
		var separated = separate(reversedDecimal);
		result += decimalSplitter + reverse(separated);
	}
}

module.exports = formatNumber;