function collection() {
	var c = {};
	var subjects = [];

	c.add = function(subject){
		subjects.push(subject);
	};

	c.remove = function(subject){
		for(var i=subjects.length-1; i>=0; i--) {
			if(subjects[i] === subject)
				subjects.splice(i, 1);
		}	
	};

	c.forEach = function(functionToExecute){
		var tempSubjects = [];
		for(var i=0; i<subjects.length; i++) {
			tempSubjects.push(subjects[i]);
		}	
		for(var j=0; j<tempSubjects.length; j++) {
			functionToExecute(tempSubjects[j]);
		}	
	};

	c.clear = function(){
		subjects = [];
	};

	c.toArray = function(){
		return subjects;
	};

	return c;
}

module.exports = collection;