function inlineFormPresenter() {
	var c = {};
	var formPresenter = require('./formPresenter')();
	formPresenter.addClass('form-inline');

	c.activate = function(region){
		formPresenter.activate(region);
	};
	
	c.add = function(element){
		formPresenter.add(element);
	};
	c.appendChild = c.add;

	c.remove = function(element){
		formPresenter.remove(element);
	};
	c.removeChild = c.remove;

	c.deactivate = function(){
		formPresenter.deactivate();
	};

	c.subscribeSubmit = function(callback) {
		formPresenter.subscribeSubmit(callback);
	};

	c.unsubscribeSubmit = function(callback){
		formPresenter.unsubscribeSubmit(callback);
	};

	return c;
}

module.exports = inlineFormPresenter;
