var $ = require('jquery');
var negotiateModels = require('./negotiateModels');

function negotiateSetup(options) {	
	var emptyModel = function() {};
	emptyModel.subscribeValidationSuccess = function() {};
	emptyModel.subscribeValidationFailed = function() {};
	emptyModel.unsubscribeValidationSuccess = function() {};
	emptyModel.unsubscribeValidationFailed = function() {};

	var defaults = {
		displayMember: 'text',
		valueMember: 'value',
		model: emptyModel
	};

	var setup =  $.extend(true, defaults, options);
	setup.models = negotiateModels(setup);
	return setup;
}

module.exports = negotiateSetup;