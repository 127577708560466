var ajaxServiceCore = require('./ajaxServiceCore');
var endpoint = '../auth';
var loginSuccessEventEmitter = require('../emitEvent')();
var newPromise = require('shared').promise;

function loginRequestSender(requests) {
	var def = newPromise();
	var serializedRequests = require('./serialize')(requests);
	ajaxServiceCore(serializedRequests, endpoint, 'text')
		.then(onSuccess, onFailed);

	function onSuccess(data, textStatus, jqXhr) {
		loginSuccessEventEmitter();
		def.resolve();
	}

	function onFailed(jqXhr, textStatus, error) {
		def.reject(error);
	}

	return def.promise;
}

loginRequestSender.subscribeLoginSuccess = function(callback) {
	loginSuccessEventEmitter.add(callback);
}

loginRequestSender.unsubscribeLoginSuccess = function(callback) {
	loginSuccessEventEmitter.remove(callback);
}

module.exports = loginRequestSender;