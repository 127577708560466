var promise = require('shared').promise;

function notEmptyValidator(value) {
	var def = promise();
	if(!value)
		def.reject("Expected a value");
	else
		def.resolve();
	return def.promise;
}

module.exports = notEmptyValidator;