var newEmitEvent = require('../emitEvent');

function emitEventWorkAround() {
	var coreEmitter = newEmitEvent();
	var internalEmitter = newEmitEvent();

	internalEmitter.add(onEmit);

	var c = function() {
		internalEmitter.apply(null, arguments);
	}

	function onEmit(e) {
		if(e.srcElement)
			try { e.target = e.srcElement; } catch(e) {}
		else
			e.srcElement = e.target;
		coreEmitter(e);
	}

	for(var property in coreEmitter) {
		c[property] = coreEmitter[property];
	}

	return c;
}

module.exports = emitEventWorkAround;
