var executeValidate = require('../executeValidate');
var promise = require('../../promise');

function newExecute(validate) {

    function execute() {
        return executeValidate(validate, arguments).then(reject, resolve);

        function resolve () {
        	return;
        }

        function reject() {
            var def = promise();
            return def.reject();
        }

    }

    return execute;
}

module.exports = newExecute;
