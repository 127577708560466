var setViewData = require('./viewTools/setViewData');
var newLabelPresenter = require('./labelPresenter');
var newChangeEventListener = require('./changeEventListener');
var negotiateStep = require('./input/negotiateStep');
var purifyInput = require('./input/purifyInput');
var negotiateToLocaleString = require('./input/negotiateToLocaleString');
var newNegotiateReadOnly = require('./negotiateReadOnly');
var newFieldErrorPresenter = require('./fieldErrorPresenter');
var negotiateAttribute = require('./negotiateAttribute');
var negotiateSetRequired = require('./negotiateSetRequired');

function inputPresenter(options) {
    var c = {};
    var view = require('./inputView.tpl.html');
    var labelPresenter = newLabelPresenter(options.label);
    var changeEventListener = newChangeEventListener();
    var negotiateReadOnly = newNegotiateReadOnly(options.model);
    var fieldErrorPresenter = newFieldErrorPresenter(options.model);
    negotiateStep(view.input, options);
    negotiateAttribute('maxLength', options.model, view.input);
    view.input.placeholder = options.placeholderText || '';
    view.input.type = options.type;


    c.activate = function(region) {
        view.activate(region);
        negotiateSetRequired(view.wrapper, options.model);
        options.model.subscribeChange(onModelChanged);
        negotiateReadOnly.activate(view.input);
        labelPresenter.activate(view.labelRegion);
        changeEventListener.add(view.input, onValueChanged);
        fieldErrorPresenter.activate(view.wrapper);
        onModelChanged();
    };

    c.deactivate = function() {
        options.model.unsubscribeChange(onModelChanged);
        changeEventListener.remove(view.input, onValueChanged);
        labelPresenter.deactivate();
        negotiateReadOnly.deactivate();
        fieldErrorPresenter.deactivate();
        view.deactivate();
    };

    function onModelChanged() {
        changeEventListener.disable();
        var value = options.model();
        setViewData(view, {
            value: value
        });
        changeEventListener.enable();
    }

    function onValueChanged(e) {
        var value = purifyInput(changeEventListener, options, e);
        options.model.unsubscribeChange(onModelChanged);
        options.model(value).then(reSubscribe, reSubscribe);
    }

    function reSubscribe() {
        options.model.subscribeChange(onModelChanged);
    }

    c.focus = function() {
        view.input.focus();
    };

    return c;
}

module.exports = inputPresenter;
