var shared = require('shared');
var date = shared.date;
var strictMode = true;
var promise = shared.promise;

function validateDate (dateSubject) {
	if(!dateSubject)
		return promise.reject(new Error('Date value is null'));
	var def = promise();	
	var parsedDate = date(dateSubject, 'L', strictMode);
	if(!parsedDate.isValid())
		def.reject(new Error('Unrecognized Date Format.'));
	else if(parsedDate.year() <= 0)
		def.reject(new Error('Year must be higher than 0000.'));
	else
		def.resolve();
	return def.promise;
}

module.exports = validateDate;