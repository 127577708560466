function queryBuilder(baseUrl) {
	var c = {};
	var params = {};
	
	c.add = function(keyValues) {
		for(var key in keyValues) {
			params[encodeURIComponent(key)] = encodeURIComponent(keyValues[key]);
		}
		return c;
	};

	c.toString = function() {
		var result = baseUrl + '?';
		for(var key in params) {
			result += key + '=' + params[key] + '&';
		}
		return result.substring(0, result.length-1);
	};

	return c;
}

module.exports = queryBuilder;