var executeValidate = require('../executeValidate');

function newExecute(validate, validate2) {

    function execute() {
        return executeValidate(validate, arguments)
            .then(wrapExecuteValidate(arguments))
            .then(null, onFailed);
    }

    function wrapExecuteValidate(args) {
        return function() {
            return executeValidate(validate2, args);
        };
    }

    function onFailed(e) {
        if (execute.message)
            throw execute.message;
        throw e;
    }

    return execute;
}

module.exports = newExecute;
