var newCoreListener = require('./numberChangeEventListenerCore');

function numberChangeEventListener() {
	var c = {};
	var elements = [];
	var listeners = [];

	c.add = function(element, callback){
		var index = elements.indexOf(element);
		if(index === -1) {
			var coreListener = newCoreListener(element);
			elements.push(element);
			listeners.push(coreListener);
			coreListener.add(callback);
		}
		else {
			listeners[index].add(callback);
		}
	};

	c.remove = function(element, callback){
		var index = elements.indexOf(element);
		listeners[index].remove(callback);
	};

	c.enable = function() {
		listeners.forEach(function(listener) {
			listener.enable();
		});
	};

	c.disable = function() {
		listeners.forEach(function(listener) {
			listener.disable();
		});
	};

	return c;
}

module.exports = numberChangeEventListener;