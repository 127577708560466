var c = {};

c.alwaysTrue = require('./alwaysTrue');
c.alwaysTrueValidator = require('./alwaysTrue');
c.date = require('./validateDate');
c.dateTime = require('./dateTimeValidator');
c.empty = require('./emptyValidator');
c.integer = require('./integerValidator');
c.integerBetween = require('./integerBetweenValidator');
c.integerValidator = require('./integerValidator');
c.isoCountryCode = require('./validateCountryCode');
c.maxNChars = require('./maxNChars');
c.maxNLetters = require('./maxNLetters');
c.maxTenChars = c.maxNChars(10);
c.nonNegativeInteger = require('./nonNegativeIntegerValidator');
c.nonNegativeIntegerValidator = require('./nonNegativeIntegerValidator');
c.nonNegativeNumber = require('./nonNegativeNumberValidator');
c.nonNegativeNumberValidator = require('./nonNegativeNumberValidator');
c.notEmpty = require('./notEmptyValidator');
c.notEmptyCollection = require('./validateNotEmptyCollection');
c.notEmptyValidator = require('./notEmptyValidator');
c.notNull = require('./notNullValidator');
c.nullableDate = require('./validateNullableDate');
c.nullableDateTime = require('./validateNullableDateTime');
c.nullableNonNegativeInteger = require('./nullableNonNegativeIntegerValidator');
c.nullableNonNegativeIntegerValidator = require('./nullableNonNegativeIntegerValidator');
c.nullableNonNegativeNumber = require('./nullableNonNegativeNumberValidator');
c.nullableNonNegativeNumberValidator = require('./nullableNonNegativeNumberValidator');
c.nullablePositiveInteger = require('./nullablePositiveIntegerValidator');
c.nullablePositiveNumber = require('./nullablePositiveNumberValidator');
c.nullableTime = require('./validateNullableTime');
c.numeric = require('./numericValidator');
c.numericValidator = require('./numericValidator');
c.optional = require('./optionalValidator');
c.optionalValidator = require('./optionalValidator');
c.positiveInteger = require('./positiveIntegerValidator');
c.positiveNumber = require('./positiveNumberValidator');
c.tenDigits = require('./tenDigits');
c.time = require('./validateTime');
c.validEmail = require('./validEmail');

module.exports = c;
