function negotiateUpperCase(changeEventListener, options, e) {
	if(!options.upperCase)
		return e.target.value;
	changeEventListener.disable();
	var upperCased = e.target.value.toUpperCase();
	e.target.value = upperCased;
	changeEventListener.enable();
	return upperCased;
}

module.exports = negotiateUpperCase;