 var newExecuteValidate = require('./monadicAnd/newExecuteValidate');
 var addOperators = require('./addOperators');

function monadicAnd(validator) {
	if(validator.and)
		return validator;
	var execute = newExecuteValidate(validator);
	return addOperators(execute);
}

module.exports = monadicAnd;