const transporters = require('../transporters.json');
const decideSearchType = require('./model/decideSearchType');
const { defaultTransporterKey } = require('core');
const {
    modelProperty,
    queryParser,
    validators,
} = require('gui');

module.exports = function model() {
    const c = {};
    c.search = modelProperty(validators.alwaysTrue, '');
    c.searchType = modelProperty(validators.alwaysTrue, 'ref');
    c.transporterCode = modelProperty(validators.alwaysTrue, window.localStorage.getItem(defaultTransporterKey) || 'tim');
    c.search.subscribeChange(newValue => c.searchType(decideSearchType(newValue)));

    return c;
}
