function emptyPresenter() {
	var c = {};

	c.activate = function(){
			
	};

	c.deactivate = function(){
			
	};	

	return c;
}

module.exports = emptyPresenter;