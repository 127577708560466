var integerValidator = require('./integerValidator');
var numberInRange = require('./integer/numberInRange');
var booleanValidate = require('shared').booleanValidate;

function integerBetween(from, to) {
	var boundNumberInRange = numberInRange.bind(null, from, to);
	var validator = booleanValidate.and(integerValidator, boundNumberInRange);
	validator.message = 'Expected a number between ' + from + ' and ' + to;
	return validator;
}

module.exports = integerBetween;