var newEmitEvent = require('./emitEvent');
var newInternalCallback = require('./keyEventListener/newInternalCallback');

function keydownEventListener(optionalKeyCode) {
	var eventListenerCore = require('./eventListenerCore')('keydown');
	if(!optionalKeyCode)
		return eventListenerCore;

	var c = {};
	var list = [];

	c.add = function(element, callback){
		var emitter = newEmitEvent();
		eventListenerCore.add(element, emitter);
		var boundInternalCallback = newInternalCallback().bind(null, callback, optionalKeyCode);
		emitter.add(boundInternalCallback);
		list.push({
			element: element,
			callback: callback,
			emitter:emitter,
			boundInternalCallback: boundInternalCallback
		});
	};

	c.remove = function(element, callback){
		var emitter = {};
		var boundInternalCallback = {};
		list.forEach(function(item) {
			if(item.element === element && item.callback === callback) {
				emitter = item.emitter;
				boundInternalCallback = item.boundInternalCallback
			}
		})
		eventListenerCore.remove(element, emitter);
		emitter.remove(boundInternalCallback);
	};

	c.enable = eventListenerCore.enable;
	c.disable = eventListenerCore.disable;

	return c;
}

module.exports = keydownEventListener;