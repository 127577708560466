function modelToDto(model) {
	var dto = {};
    for(var i in model) {
        if(model[i].isProperty)
            dto[i] = model[i]();
        else if(model[i].toDto)
            dto[i] = model[i].toDto();
    }
    return dto;
}

module.exports = modelToDto;