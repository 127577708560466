const moment = require('moment');

module.exports = function setLocale() {
    const languages = window.acceptedlanguages.accepted;
    const locales = moment.locales();
    for(let language of languages) {
        if(locales.indexOf(language) === -1)
            continue;

        moment.locale(language);
        return true;
    }
    return false;
}
