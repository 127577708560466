
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<div class="clickableCollapsibleDivPresenter-wrapper" data-name="wrapperRegion">\r\n\t<div class="clickableCollapsibleDivPresenter-header" data-name="headerRegion"></div>\r\n</div>\r\n');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
