var requestSender = require('./sendRequest');

function request(typeName) {
	var c = {};
	var object = {};
	object.__type = typeName;

	c.add = function(propertyName,propertyValue) {
		object[propertyName] = propertyValue;
		return c;
	};
	
	c.send = function() {
		return requestSender(object);
	};

	return c;
}

module.exports = request;