var shared = require('shared');
var promise = shared.promise;
var newDate = shared.date;

function dateTimeValidator(dateTime) {
	if(!dateTime)
		return promise.reject(new Error('Datetime value is null'));
	
	var def = promise();
	var parsedDate = newDate(dateTime, 'L LT');
	if(!parsedDate.isValid())
		def.reject(new Error('Invalid date or time'));
	else if(parsedDate.year() <= 0)
		def.reject(new Error('Year must be higher than 0000.'));
	else
		def.resolve();
	return def.promise;
}

module.exports = dateTimeValidator;