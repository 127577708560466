var booleanValidate = require('shared').booleanValidate;

function newValidateModel(model) {
	var args = [];
	for (var name in model) {
		var property = model[name];
		if (property && property.validate)
			args.push(property.validate);
	}
	return booleanValidate.andd.apply(null, args);
}

module.exports = newValidateModel;