var newEmitEvent = require('./newNegotiateEmitChanged');    

function numberChangeEventListenerCore(element) {
    var c = {};
    var emitEvent = newEmitEvent(element);
    var subscribeCount = 0;

    c.add = function(callback) {       
        emitEvent.add(callback);
        negotiateAddEventListeners();
    };

    c.remove = function(callback) {
        emitEvent.remove(callback);
        negotiateRemoveEventListeners();
    };

    c.enable = emitEvent.enable;
    c.disable = emitEvent.disable;

    function negotiateAddEventListeners() {
        if (subscribeCount === 0) {
            element.addEventListener('focus', onFocus);
            element.addEventListener('blur', onBlur);
            element.addEventListener('input', onInput);
        }
        subscribeCount++;
    }

    function onFocus() {
        emitEvent.previousValue = element.value;
    }

    function onBlur() {
        emitEvent();
    }

    function onInput() {
        emitEvent.isDirty = true;
    }


    function negotiateRemoveEventListeners() {
        if (subscribeCount === 1) {
            element.removeEventListener('focus', onFocus);
            element.removeEventListener('blur', onBlur);
            element.removeEventListener('input', onInput);
        } else if (subscribeCount !== 0)
            subscribeCount--;
    }

    return c;
}

module.exports = numberChangeEventListenerCore;
