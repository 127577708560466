var clone = require('./clone');
var newInputPresenter = require('./inputPresenter');
var newGlyphIconInputPresenter = require('./glyphIconInputPresenter');

function textInputPresenter(options) {
    var newCorePresenter;
    if (options.icon)
        newCorePresenter = newGlyphIconInputPresenter;
    else
        newCorePresenter = newInputPresenter;
    options = clone(options);    
    options.type = "text";  
    return newCorePresenter(options);
}

module.exports = textInputPresenter;