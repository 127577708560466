function listGroupItemPresenter() {
	var c = {};
	var item = require('../document').createElement('li');
	require('../classManipulator')().addTo(item, 'list-group-item');
	var _region;

	c.activate = function(region){
		_region = region;
		_region.add(item);
	};

	c.add = function(element){
		item.appendChild(element);
	};
	c.appendChild = c.add;

	c.remove = function(element){
		item.removeChild(element);
	};
	c.removeChild = c.remove;

	c.deactivate = function(){
		_region.remove(item);
	};

	return c;
}

module.exports = listGroupItemPresenter;