var negotiateDefaults = require('./negotiateDefaults');

function getAscendingComparer(propertyName) {
    return function(a, b) {
        var aDefault = negotiateDefaults(a[propertyName], '');
        var bDefault = negotiateDefaults(b[propertyName], '');
        
        if(typeof aDefault === 'function')
        	aDefault = aDefault();
        if(typeof bDefault === 'function')
        	bDefault = bDefault();

        if (aDefault.toString().length === 0)
            return 1;
        if (bDefault.toString().length === 0)
            return -1;
        return String.prototype.localeCompare.bind(aDefault)(bDefault);
    }
}

module.exports = getAscendingComparer;
