var ajaxServiceCore = require('./ajaxServiceCore');
var endpoint = 'public';
var newPromise = require('shared').promise;

function publicRequestSender(request) {
	var def = newPromise();
	var serializedRequests = require('./serialize')(request);
	ajaxServiceCore(serializedRequests, endpoint)
		.then(onSuccess, onFailed);

	function onSuccess(data, textStatus, jqXhr) {
		def.resolve(data);
	}

	function onFailed(jqXhr, textStatus, error) {
		def.reject(error);
	}

	return def.promise;
}

module.exports = publicRequestSender;