
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<div data-name="overlay" class="modalPresenter-overlay" style="opacity: 0;">\r\n</div>\r\n<div class="modalPresenter-wrapper" data-name="wrapper" style="-webkit-transform:translateY(-100%);transform:translateY(-100%)">\r\n    <div class="modalPresenter-contentWrapper">\r\n        <button data-name="closeButton" class="modalPresenter-close">&times;</button>\r\n        <div data-name="contentRegion" class="modalPresenter-content">\r\n        </div>\r\n    </div>\r\n</div>\r\n');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
