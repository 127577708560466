var jQuery = require('jquery');

function send(serializedData, url, dataType) {
	dataType = dataType || 'json';
    return jQuery.ajax({
        type: "POST",
        url: url,
        data: serializedData,
        contentType: "application/json; charset=utf-8",
        dataType: dataType,
        timeout: 15000
    });
}

module.exports = send;
