module.exports = function setStyles(overlayElement, wrapperElement) {
    overlayElement.classList.add('modalPresenter-active');
    wrapperElement.classList.add('modalPresenter-active');

    wrapperElement.style.left = '0';
    wrapperElement.style.top = '0';

    var height = wrapperElement.offsetHeight;
    var width = wrapperElement.offsetWidth;

    wrapperElement.style.left = ((window.innerWidth / 2) - (width / 2)) + 'px';
    wrapperElement.style.top = ((window.innerHeight / 2) - (height / 2)) + 'px';
}
