var emitEvent = require('./emitEvent');
var newValidateModel = require('./newValidateModel');

function modelCollection(_array) {
	var c = {};
	var changeEmitter = emitEvent();
	var collectionChangeEmitter = emitEvent();

	c.validate = function() {
		var validate = newValidateModel(_array);
		return validate.apply(null, arguments);
	};

	_array.forEach(function(item) {
        item.subscribeChange(changeEmitter);
	});

	c.forEach = function(callback) {
		_array.forEach(callback);
	};

	c.sort = function(comparer) {
		_array.sort(comparer);
		return c;
	};

	c.get = function(index){
		return _array[index];
	};

	c.subscribeChange = function(callback) {
		changeEmitter.add(callback);
	};

	c.unsubscribeChange = function(callback) {
		changeEmitter.remove(callback);
	};

	c.subscribeCollectionChange = function(callback){
		collectionChangeEmitter.add(callback);
	};

	c.unsubscribeCollectionChange = function(callback){
		collectionChangeEmitter.remove(callback);
	};

	c.push = function(item) {
        item.subscribeChange(changeEmitter);
		_array.push(item);
		changeEmitter();
		collectionChangeEmitter('push', item, _array.length - 1);
	};

    c.pushRange = function(items = []) {
        for(let item of items) {
            item.subscribeChange(changeEmitter);
            _array.push(item);
        }
        changeEmitter();
        collectionChangeEmitter('pushRange', items);
    };

	c.removeAt = function(index) {
		var item = _array[index];
        item.unsubscribeChange(changeEmitter);
		_array.splice(index, 1);
		changeEmitter();
		collectionChangeEmitter('remove', item, index);
	};

	c.remove = function(removeItem) {
		c.removeAt(_array.indexOf(removeItem));
	};

	c.removeAll = function() {
		for(var i = _array.length - 1; i >= 0; i--)
			_array[i].unsubscribeChange(changeEmitter);
        collectionChangeEmitter('removeAll');
		changeEmitter();
		_array = [];
	};

    c.disableEvents = function() {
        collectionChangeEmitter.disable();
        changeEmitter.disable();
    };

    c.enableEvents = function() {
        collectionChangeEmitter.enable();
        changeEmitter.enable();
    };

	c.toDto = function() {
		var result = [];
		_array.forEach(function(element) {
			result.push(element.toDto());
		});
		return result;
	};

	c.toArray = function() {
		return _array.slice();
	};

	c.isEmpty = function(){
		return _array.length === 0;
	};

	c.update = function(other) {
		_array.forEach(function(element) {
			element.unsubscribeChange(changeEmitter);
		});

		_array = other.toArray();

		_array.forEach(function(element) {
			element.subscribeChange(changeEmitter);
		});

		collectionChangeEmitter();
	};

	c.isReadOnly = function() {};

    Object.defineProperty(c, 'length', {
        get: () => _array.length
    });

	return c;
}

module.exports = modelCollection;
