
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<div class="inputView-label" data-name="labelRegion"></div>\r\n<div data-name="wrapper" class="inputView-wrapper">\r\n    <input class="inputView-input" data-name="input" data-property="value">\r\n\t<span class="input" data-name="validationIcon"></span>\r\n</div>\r\n');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
