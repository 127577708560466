var newEmitEvent = require('./eventListener/emitEventWorkAround');

function eventListenerCore(eventName) {	
	var elements = [];	
	var emitters = [];	
	var counters = [];

	var c = {};

	c.add = function(element, callback) {
		var emitter = newEmitter(element);
		emitter.add(callback);
	};

	function newEmitter(element) {
		var index = elements.indexOf(element);
		if (index === -1) {
			counters.push(1);
			var emitter = newEmitEvent();
			emitters.push(emitter);
			elements.push(element);
			element.addEventListener(eventName,emitter);			
			return emitter;
		}
		else {
			counters[index]++;
			return emitters[index];					
		}
	}

	c.remove = function(element, callback) {
		var emitter;
		var index = elements.indexOf(element);		
		emitter = emitters[index];
		emitter.remove(callback);
		var count = --counters[index];
		if (count === 0) {
			element.removeEventListener(eventName,emitter);			
			counters.splice(index,1);
			elements.splice(index,1);
			emitters.splice(index,1);
		}
	};

	c.enable = function() {
		emitters.forEach(function(emitter) {
			emitter.enable();
		});
	};

	c.disable = function() {
		emitters.forEach(function(emitter) {
			emitter.disable();
		});
	};

	return c;
}

module.exports = eventListenerCore;