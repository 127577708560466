var executeValidate = require('../executeValidate');
var promise = require('../../promise');

function newExecute(validate, validate2) {
    function execute() {
        var errors = [];
        var args = arguments;
        return executeValidate(validate, arguments).then(null, onError1)
            .then(negotiateReject);

        function onError1(error) {
            logError(error);
            return executeValidate(validate2, args).then(null, logError);
        }

        function logError(error) {
            if (error instanceof Array) {
                errors = error.concat(errors);
            } else
                errors.unshift(error);
        }

        function negotiateReject() {
            if (errors.length >= 2) {
                var def = promise();
                if (execute.message)
                    return def.reject(execute.message);
                return def.reject(errors);
            }
        }

    }

    return execute;
}

module.exports = newExecute;
