var window = require('./window');

var queryParser = {};

queryParser.getAll = function() {
    var result = {};
    var query = window.location.href.split("?")[1];
    query.split("&").forEach(function(e) {
        var keyValue = e.split("=");
        result[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1]);
    });

    return result;
};

queryParser.get = function(parameter) {
    return _fetch(parameter);
};

function _fetch(name) {
    var query = window.location.href.split("?")[1];
    var regex = new RegExp(encodeURIComponent(name) + "=([^&#]*)");
    var results = regex.exec(query);
    if (results === null)
        return "";
    else
        return decodeURIComponent(results[1]);
}

module.exports = queryParser;
