const setLocale = require('./transportsiden/setLocale');

document.addEventListener('DOMContentLoaded', function() {
	var { getTranslationDictionary } = require('core');
    var simpleTranslationProvider = require('tplify/dist/simpleTranslationProvider').default;
    simpleTranslationProvider.setDictionary(getTranslationDictionary());
    var mainPresenter = require('./transportsiden/mainPresenter')();
    var mainContainer = document.getElementById('main');
    mainPresenter.activate(mainContainer);
});
