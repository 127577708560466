var orderStatusValues = function(status) {
    switch (status) {
        case -10:
            return 'Deleted';
        case 0:
            return 'Draft';
        case 10:
            return 'Booking Sent';
        case 15:
            return 'Awaiting Price';
        case 20:
            return 'For Approval';
        case 30:
            return 'Approved';
        case 40:
            return 'Invoiced';
        case 100:
            return 'External';
    }
};

addGetter('deleted', -10);
addGetter('draft', 0);
addGetter('pending', 10);
addGetter('awaitingPrice', 15);
addGetter('awaitingApproval', 20);
addGetter('approved', 30);
addGetter('invoiced', 40);
addGetter('external', 100);

function addGetter(name, value) {
    Object.defineProperty(orderStatusValues, name, {
        get: function() {
            return value;
        },
        enumerable: true
    });
}

module.exports = orderStatusValues;
