
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<div class="selectPresenter-wrapper" data-name="selectWrapper">\r\n\t<select class="selectPresenter-select" data-name="selectElement"></select>\r\n</div>');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
