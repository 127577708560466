var $ = require('jquery');

function classManipulator() {
	var c = {};

	c.addTo = classManipulator.addTo;

	c.removeFrom = classManipulator.removeFrom

	return c;
}

classManipulator.addTo = function(element) {
		var jQueryElement = $(element);
		for(var i=1; i<arguments.length; i++) {
			jQueryElement.addClass(arguments[i]);
		}
	};

classManipulator.removeFrom = function(element) {
		var jQueryElement = $(element);
		for(var i=1; i<arguments.length; i++) {
			jQueryElement.removeClass(arguments[i]);
		}
	};
//todo test

module.exports = classManipulator;
