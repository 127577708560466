const newHeaderPresenter = require('./headerPresenter');
const newSearchPresenter = require('./searchPresenter');

module.exports = function mainPresenter() {
    const view = require('./mainPresenter.tpl.html');
    var c = {};
    var headerPresenter = newHeaderPresenter();
    var searchPresenter = newSearchPresenter();

    c.activate = function(region) {
        view.activate(region);
        headerPresenter.activate(view.headerRegion);
        searchPresenter.activate(view.searchRegion);
    };

    return c;
};
