var document = require('./document');
var newEmitEvent = require('./emitEvent');
var negotiateLabel = require('./button/negotiateLabel');
var clickEventListener = require('./clickEventListener')();
var classManipulator = require('./classManipulator')();

function createButton(optionalText) {
	var c  = {};
	var _region;
	var span = document.createElement('span');
	var button = document.createElement('button');
	span.appendChild(button);
	negotiateLabel(button,optionalText);
	var emitClicked = newEmitEvent();
	button.innerHTML = optionalText;
	classManipulator.addTo(button, 'btn', 'btn-default');

	c.activate = function(region) {
		clickEventListener.add(button, onClicked);
		region.add(span);
		_region = region;
	};

	function onClicked (e) {
		emitClicked();
	}

	c.addClass = function(className){
		classManipulator.addTo(button, className);
	};

	c.deactivate = function() {
		clickEventListener.remove(button, onClicked);
		_region.remove(span);
	};

	c.enable = function() {
		button.disabled = false;
	};

	c.disable = function() {
		button.disabled = true;
	};

	c.setLabel = function(text){
		button.innerHTML = text;
	};

	c.text = c.setLabel;

	c.col = function(colSpan){
		classManipulator.addTo(span, 'col-xs-' + colSpan);
	};

	c.subscribeClicked = function(callback) {
		emitClicked.add(callback);		
	};

	c.unsubscribeClicked = function(callback) {
		emitClicked.remove(callback);		
	};

	c.element = function(){
		return button;
	};

	return c;
}

module.exports = createButton;