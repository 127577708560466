var getAscendingComparer = require('./getAscendingComparer');

function getDescendingComparer(propertyName) {
	var ascendingComparer = getAscendingComparer(propertyName);
	return function(a, b) {
		return ascendingComparer(a, b) * -1;
	};
}

module.exports = getDescendingComparer;
