var promise = require('shared').promise;

function validateNotEmptyCollection(collection) {
	var hasElements;
	collection.forEach(function() {
		hasElements = true;
	})
	if (!hasElements)
		return promise.reject('Expected not empty collection.');
	return promise(true);
}

module.exports = validateNotEmptyCollection;