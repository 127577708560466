var newCollapsibleDivPresenter = require('./collapsibleDivPresenter');

function clickableCollapsibleDivPresenter() {
    var c = {};
    var view = require('./clickableCollapsibleDivView.tpl.html');
    var collapsibleDivPresenter = newCollapsibleDivPresenter();
    var _region;

    c.activate = function(region) {
        view.activate(region);
        collapsibleDivPresenter.activate(view.wrapperRegion);
        view.headerRegion.addEventListener('click', collapsibleDivPresenter.toggle);
    };

    c.subscribeCollapse = collapsibleDivPresenter.subscribeCollapse;
    c.unsubscribeCollapse = collapsibleDivPresenter.unsubscribeCollapse;
    c.subscribeExpand = collapsibleDivPresenter.subscribeExpand;
    c.unsubscribeExpand = collapsibleDivPresenter.unsubscribeExpand;

    c.expand = function(){
        collapsibleDivPresenter.expand();
    };

    c.collapse = function() {
        collapsibleDivPresenter.collapse();
    }

    c.headerRegion = function() {
        return view.headerRegion;
    };

    c.contentRegion = function() {
        return collapsibleDivPresenter.collapsibleRegion();
    };

    c.deactivate = function() {
        view.headerRegion.removeEventListener('click', collapsibleDivPresenter.toggle);
        view.deactivate();
    };

    return c;
}

module.exports = clickableCollapsibleDivPresenter;
