var getError = _getError;

function getErrorMessage(error) {
    if (error instanceof Array) {
        return getError(error[0]);
    }
    return error.message ? error.message : error;
}

function _getError(error) {
    getError = require('./getErrorMessage');
    return getError(error);
}

module.exports = getErrorMessage;
