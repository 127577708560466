var promise = require('./promise');
var http = require('http');
var json = require('./json');

function getRequest() {
    var c = {};

    c.send = function(url) {
        var def = promise();
        http.get(url, onSuccess)
            .on("error", onError);
        return def.promise;

        function onSuccess(response) {
        response.setEncoding('utf8');
        response.on('data', getData);

            function getData(data) {
                def.resolve(json.parse(data));
            }
        }
        
        function onError(error) {
            var parsed = json.parse(error);
            def.reject(parsed);
        }
    };
    return c;
}

module.exports = getRequest;