function imagePresenter(path) {
	var c = {};
	var document = require('./document');
	var classManipulator = require('./classManipulator')();
	var clickEventListener = require('./clickEventListener')();
	var imageElement = document.createElement('img');
	require('./imagePresenter/setOptionalSource')(imageElement, path);
	var _region;
	var emitClicked = require('./emitEvent')();
	var _widthPercentage;
	
	c.activate = function(region){
		_region = region;
		_region.add(imageElement);
		clickEventListener.add(imageElement, onClicked);
		if(_widthPercentage)
			setWidth(_widthPercentage);
	};

	c.setWidthPercentageRelativeToParent = function(widthPercentage){
		if(imageElement.parentNode)
			setWidth(widthPercentage);
		else
			_widthPercentage = widthPercentage;
	};

	c.setSrc = function(path){
		imageElement.setAttribute('src', path);
	};

	function setWidth (widthPercentage) {
		imageElement.width = (widthPercentage/100 * imageElement.parentNode.clientWidth);
	}

	c.subscribeClicked = function(callback){
		emitClicked.add(callback);
	};

	c.unsubscribeClicked = function(callback){
		emitClicked.remove(callback);
	};

	c.hover = function(){
		classManipulator.addTo(imageElement, 'btn');
	};

	function onClicked () {
		emitClicked();
	}

	c.deactivate = function(){
		clickEventListener.remove(imageElement, onClicked);
		_region.remove(imageElement);
	};

	c.addClass = function(className) {
		classManipulator.addTo(imageElement, className);
	};

	c.removeClass = function(className) {
		classManipulator.removeFrom(imageElement, className);
	};

	return c;
}

module.exports = imagePresenter;