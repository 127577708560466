function labelPresenter(text) {
    var c = {};
    var view = require('./labelView.tpl.html');

    c.activate = function(region) {
        if (!text) return;
        view.setData({
            text: text
        });
        view.activate(region);
    };

    c.deactivate = function() {
        if (!text) return;
        view.deactivate();
    };

    return c;
}

module.exports = labelPresenter;
