var newEmitEvent = require('./emitEvent');
var nextModelProperty = _nextModelProperty;
var alwaysTrue = require('./validators').alwaysTrue;
var negotiateDefaults = require('shared').negotiateDefaults;

function modelProperty(validator, originalValue, initialIsRequired) {
    var isReadOnly;

    var changeEmitter = newEmitEvent();
    var validationFailedEmitter = newEmitEvent();
    var validationSuccessEmitter = newEmitEvent();
    var validChangeEmitter = newEmitEvent();
    var _value = negotiateDefaults(originalValue, null);
    var isRequired = negotiateDefaults(initialIsRequired, false);

    var setGet = function(value) {
        if (arguments.length > 0)
            return set(value);
        return get();
    };

    function set(value) {
        _value = value;
        changeEmitter(_value);
        return validator(value).then(emitValidChange).then(onValid, onInvalid);
    }

    function onValid() {
        validationSuccessEmitter();
    }

    function onInvalid(err) {
        validationFailedEmitter(err);
        throw err;
    }

    function emitValidChange() {
        validChangeEmitter(_value);
    }

    function get() {
        return _value;
    }

    setGet.subscribeChange = function(callback) {
        changeEmitter.add(callback);
    };

    setGet.unsubscribeChange = function(callback) {
        changeEmitter.remove(callback);
    };

    setGet.subscribeValidChange = function(callback) {
        validChangeEmitter.add(callback);
    };

    setGet.unsubscribeValidChange = function(callback) {
        validChangeEmitter.remove(callback);
    };

    setGet.disableValidChangeEvent = function() {
        validChangeEmitter.disable();
    };

    setGet.enableValidChangeEvent = function() {
        validChangeEmitter.enable();
    };

    setGet.subscribeValidationSuccess = function(callback) {
        validationSuccessEmitter.add(callback);
    };

    setGet.unsubscribeValidationSuccess = function(callback) {
        validationSuccessEmitter.remove(callback);
    };

    setGet.subscribeValidationFailed = function(callback) {
        validationFailedEmitter.add(callback);
    };

    setGet.unsubscribeValidationFailed = function(callback) {
        validationFailedEmitter.remove(callback);
    };

    setGet.validate = function() {
        return validator(_value).then(onValid, onInvalid);
    };

    setGet.isDirty = function() {
        return _value !== originalValue;
    };

    setGet.enableValidationEvent = function() {
        validationSuccessEmitter.enable();
        validationFailedEmitter.enable();
    };

    setGet.disableValidationEvent = function() {
        validationSuccessEmitter.disable();
        validationFailedEmitter.disable();
    };

    setGet.isProperty = true;
    setGet.isRequired = isRequired;
    
    Object.defineProperty(setGet, 'isReadOnly', {
        get: function() {
            if (!isReadOnly)
                isReadOnly = nextModelProperty(alwaysTrue, false);
            return isReadOnly;    
        },
        set: function(value) {
            isReadOnly = value;    
        }
    });

    return setGet;
}

function _nextModelProperty() {
    nextModelProperty = require('./modelProperty');
    return nextModelProperty.apply(null,arguments);
}

module.exports = modelProperty;
