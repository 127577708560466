function newDeactivate() {
	var subjects = [];

	function deactivate() {
		var copy = subjects.slice(0, subjects.length);
		for (var i = copy.length - 1; i >= 0; i--) {
			copy[i].deactivate();
		}
		subjects = [];
	}

	deactivate.remove = function(subject) {
		for (var i = subjects.length - 1; i >= 0; i--) {
			if(subjects[i] == subject)
				subjects.splice(i,1);
		}
		return deactivate;
	};

	deactivate.add = function(subject){
		subjects.push(subject);
		return deactivate;
	};

	return deactivate;
}

module.exports = newDeactivate;