var emitUnauthorized = require('../emitEvent')();

var unauthorized = function(serializedData){
	return function(){
		emitUnauthorized(serializedData);
	};
};

unauthorized.subscribeUnauthorized = function(callback){
	emitUnauthorized.add(callback);
};

unauthorized.unsubscribeUnauthorized = function(callback){
	emitUnauthorized.remove(callback);
};

module.exports = unauthorized;