
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" data-name="rootElement">\r\n    <div class="modal-dialog modal-sm" data-name="modalElement">\r\n        <div class="modal-content">\r\n            <div class="modal-header">\r\n                <button type="button" class="close" data-dismiss="modal">\r\n                    <span aria-hidden="true">&times;</span>\r\n                    <span class="sr-only">Close</span>\r\n                </button>\r\n                <h4 class="modal-title" data-property="headerText"></h4>\r\n            </div>\r\n            <div class="modal-body" data-property="contentText">\r\n            </div>\r\n            <div class="modal-footer">\r\n                <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>\r\n                <button type="button" class="btn btn-default" data-dismiss="modal" data-name="okButton">OK</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
