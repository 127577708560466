function listGroupPresenter() {
	var c = {};
	var listGroup = require('./document').createElement('ul');
	require('./classManipulator')().addTo(listGroup, 'list-group');
	var newItemPresenter = require('./listGroup/listGroupItemPresenter');
	var itemDictionary = [];
	var itemCounter = 0;
	var _region;

	c.activate = function(region){
		_region = region;
		_region.add(listGroup);
	};
	
	c.add = function(element){
		element.viewIdentifier = itemCounter++;
		var itemPresenter = newItemPresenter();
		itemDictionary[element.viewIdentifier] = itemPresenter;
		itemPresenter.activate(listGroup);
		itemPresenter.add(element);
	};
	c.appendChild = c.add;

	c.remove = function(element){
		var itemPresenter = itemDictionary[element.viewIdentifier];
		if(itemPresenter) {
			itemPresenter.remove(element);
			itemPresenter.deactivate();
			delete itemDictionary[element.viewIdentifier];
		}
	};
	c.removeChild = c.remove;

	c.deactivate = function(){
		_region.remove(listGroup);
	};

	return c;
}

module.exports = listGroupPresenter;

//<ul class="list-group">