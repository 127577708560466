var _moment;

if (typeof moment === 'undefined')
    _moment = require('moment-timezone');
else
    _moment = moment;



function newDate(source) {
	if (arguments.length === 0)
		return _moment();
	if (source === '' || source === undefined || source === null)
		return null;
	return _moment.apply(_moment, arguments);
};

newDate.locale = function(lang) {
	_moment.locale(lang);
}
for (var name in _moment) {
	newDate[name] = _moment[name];
};



module.exports = newDate;
