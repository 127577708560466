
var ViewFactory = require('tplify/dist/viewFactory').default;

delete module.exports;

var viewFactory = new ViewFactory('<div class="form-error-label-wrapper">\r\n\t<div data-name="errorLabel" class="form-error-label" data-property="errorMessage"></div>\r\n</div>');

Object.defineProperty(module, 'exports', {
get: function() {
    return viewFactory.create();
}
});
