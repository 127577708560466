var or = function() {
    or = require('./binaryOr');
    return or.apply(null, arguments);
};

var orr = function() {
    orr = require('./binaryOrr');
    return orr.apply(null, arguments);
};
var and = function() {
    and = require('./binaryAnd');
    return and.apply(null, arguments);
};

var andd = function() {
    andd = require('./binaryAndd');
    return andd.apply(null, arguments);
};

var not = function() {
    not = require('./not');
    return not.apply(null, arguments);
};

function addOperators(validate) {

    validate.or = function() {
    	return operator(or, arguments);
    };

    function operator(op,args) {
    	var nextArgs = [validate];
    	nextArgs = nextArgs.concat(Array.prototype.slice.call(args, 0));
    	return op.apply(null,nextArgs);
    }

    validate.orr = function() {
    	return operator(orr, arguments);   
    };

    validate.and = function() {
    	return operator(and, arguments);   
    };

    validate.andd = function() {
    	return operator(andd, arguments);   
    };

    validate.not = function() {
    	return not(validate);
    };
    return validate;
}

module.exports = addOperators;
